<template>
  <div v-if="dataLoading" class="spinner-border m-auto" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div v-else style="width: 100%">
    <DxChart v-if="can_list"
        id="chart"
        :data-source="dataSource"
        palette="Harmony Light"
        title="Revenue"
    >
      <DxCommonSeriesSettings
          argument-field="complaint"
          type="fullstackedbar"
      />
      <DxSeries
          value-field="count"
          name="Count"
          axis="frequency"
          type="bar"
          color="#fac29a"
      />
      <DxSeries
          name="BOQ"
          value-field="cumulativePercentage"
          axis="percentage"
          type="spline"
          color="#6b71c3"
      />

      <DxArgumentAxis>
        <DxLabel overlapping-behavior="stagger"/>
      </DxArgumentAxis>

      <DxValueAxis :tick-interval="300" name="frequency" position="left"/>
      <DxValueAxis
          :tick-interval="20"
          :show-zero="true"
          :value-margins-enabled="false"
          name="percentage"
          position="right"
      >
        <DxLabel :customize-text="customizePercentageText"/>
        <DxConstantLine
            :value="80"
            :width="2"
            color="#fc3535"
            dash-style="dash"
        >
          <DxLabel :visible="false"/>
        </DxConstantLine>
      </DxValueAxis>

      <DxTooltip
          :enabled="true"
          :shared="true"
          :customize-tooltip="customizeTooltip"
      />

      <DxLegend vertical-alignment="top" horizontal-alignment="center"/>
    </DxChart>
      <h1 v-else>{{ $t("noPermission") }}</h1>
  </div>
</template>
<script>
import DxChart, {
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLabel,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxConstantLine
} from 'devextreme-vue/chart';

import axios from '@/libs/axios';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLabel,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxConstantLine
  },

  data() {
    return {
      areas: [],
      error: '',
      dataLoading: false,
      dataSource: {},
      can_list: true
    };
  },
  async mounted() {
    await this.getPageAuths();
    await this.getWorkData();
  },
  methods: {
    async getPageAuths() {
      const pageAuths = await axios.post("/get-page-auth", {page: "Revenue Report"});
      this.can_list = pageAuths.data.can_list;
    },
    getWorkData() {
      this.dataLoading = true;
      this.error = '';
      axios
          .get('/list-work-by-pivot')
          .then((response) => {
            if (response.status === 200) {
              this.areas = response?.data?.result || [];
              this.dataLoading = false;
              const data = this.areas.sort((a, b) => b.Amount1 - a.Amount1);
              // const totalCount = data.reduce((prevValue, item) => prevValue + item.Amount1, 0);
              let totalCount = 0;
              data.forEach((a) => {
                if (a.Amount1 !== undefined) {
                  totalCount += a.Amount1;
                }
              });
              let cumulativeCount = 0;

              this.dataSource = data.map((item) => {
                cumulativeCount += item.Amount1;
                return {
                  complaint: item.activityWbsSubBoqCode,
                  count: item.Amount1,
                  cumulativePercentage: Math.round(
                      (cumulativeCount * 100) / totalCount
                  )
                };
              });
            } else {
              this.error = response?.data?.message || 'Unexpected API error';
              this.dataLoading = false;
            }
          })
          .catch((e) => {
            this.error = e?.response?.data?.message || e?.message;
            this.dataLoading = false;
          });
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name"><span class="top-series-name">${pointInfo.points[0].seriesName}</span>: </div><div class="value-text"><span class="top-series-value">${pointInfo.points[0].valueText}</span></div><div class="series-name"><span class="bottom-series-name">${pointInfo.points[1].seriesName}</span>: </div><div class="value-text"><span class="bottom-series-value">${pointInfo.points[1].valueText}</span>% </div></div></div>`
      };
    },

    customizePercentageText({valueText}) {
      return `${valueText}%`;
    }
  }
};
</script>
<style>
#chart {
  height: 440px;
}

.tooltip-header {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
  width: 170px;
}

.tooltip-body .series-name {
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 126px;
}

.tooltip-body .value-text {
  display: inline-block;
  line-height: 1.5;
  width: 30px;
}
</style>
